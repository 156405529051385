/**
 * Slide directive
 */
.directive('slide', [ '$timeout', function($timeout) {
    /**
     * SlideObject
     *
     * SlideObjects are objects within a Slide
     * 
     * @param {Slide}       slide           Reference to the slide containing this object
     * @param {object}      elem            DOM element for the object
     */
    var SlideObject = function( slide, elem ) {
        // save reference of this into that
        var that = this;

        // properties
        this.slide      = slide;
        this.elem       = elem;
        this.isSVG      = elem.nodeName.toLowerCase() === 'svg' ? true : false;
        this.isLoading  = true;
        this.width      = JustJS.dom.outerWidth(elem);
        this.height     = JustJS.dom.outerHeight(elem);

        this.handlers   = {
            onResize: function(e) {
                console.log(that);
            },
            register: function() {
                that.slide.$scope.$on('stageResize', this.onResize)
            }
        }
        this.handlers.register();
    };
    /**
     * Resets the SlideObject (position, opacity, ...) to the initial state
     * @return {void}
     */
    SlideObject.prototype.reset = function() {};
    SlideObject.prototype.resize = function() {};

    /**
     * Slide
     *
     * Part of a Slideshow and contains various SlideObjects itself
     * 
     * @param {Slideshow}   slideshow       Reference to the Slideshow
     * @param {integer}     idx             Index of the slide
     * @param {object}      elem            DOM element for the slide
     */
    var Slide = function( scope, elem ) {
        // save reference for this into that
        var that = this;

        // properties
        this.$scope     = scope;
        this.elem       = elem;
        this.index      = elem.hasAttribute('data-slide-index') ? elem.getAttribute('data-slide-index') : null;
        this.objects    = [];
        this.loading    = 0;

        this.animator   = {
        };

        this.handlers   = {
            imageLoaded: function(elem) {
                that.elem.appendChild(elem);
                that.objects.push( new SlideObject( that, elem) );
                if(--that.loading === 0) {
                    $timeout(function() {
                        slideshow.$scope.$emit('slideLoaded', that.index);
                    });
                }
            },
            register: function() {}
        };
        this.handlers.register();

        // create slide objects
        // objects are loaded from the scope / slide array
        if(this.index !== null && scope.slides[this.index]['objects']) {
            for(var i = 0; i < scope.slides[this.index]['objects'].length; ++i) {
                var obj = scope.slides[this.index]['objects'][i];
                switch(obj.type) {
                    // img
                    case 'img':
                        ++this.loading;
                        var img = document.createElement('img');
                        // register handler
                        img.onload = function() {
                            that.handlers.imageLoaded(img);
                        }
                        // set attributes
                        img.alt = obj.alt;
                        img.className = obj.classes;
                        img.src = obj.src;
                    break;
                    // svg
                    case 'svg':
                    break;
                }
            }
        }
        console.log(this);
    };
    Slide.prototype.reset = function() {};
    Slide.prototype.show = function() {};

    return {
        restrict: 'E',
        require: '^slideshow',
        compile: function(elem, attrs) {
            return {
                pre: function(scope, elem, attrs, ctrl) {
                    // instantiate slide object
                    new Slide( scope, elem[0] );
                },
                post: function(scope, elem, attrs, ctrl) {
                }
            }
        }
    };
}]);